<template>
  <section class="mt-2 text-wrap bodytext text-break" v-if="showContent">
    <div v-html="messageContent"></div>
    <iframe v-if="1 == 0" :srcdoc="messageContent" allowfullscreen="false" class="no-border" sandbox></iframe>
  </section>
</template>
<script>
export default {
  components: {},
  props: ["body"],
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    messageContent() {
      //return "<html><head><link href='https://"+window.location.hostname+"/css/iframe.css' rel='stylesheet'></head><body>"+this.body+"</body></html>";
      let text =  this.body.trim();
      //text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
      return text;
    },
    showContent(){
      if(this.body.trim() == "")
      {
        return false;
      }else{
        return true;
      }
    }
  },
  mounted() {
  },
};
</script>
<style></style>
